import { type Entity } from '@tunasong/schemas'
import { type Persisted } from '@tunasong/schemas'

import { useMemo } from 'react'
import { entitiesApi } from '../api/entities.js'

/** Load entity, partially loading children if those have not been loaded */
export const useEntitiesById = <T extends Entity = Persisted<Entity>>(ids: string[] = []) => {
  const entityIds = useMemo(() => ids.filter(id => id !== 'ROOT').filter(Boolean), [ids])
  const {
    currentData: entities,
    isLoading,
    isSuccess,
    error,
    isError,
  } = entitiesApi.useLoadEntitiesQuery({ ids: entityIds }, { skip: !entityIds || entityIds.length === 0 })

  /** Some components require the entities in the specified order */
  const orderedEntities = useMemo(
    () => entityIds.map(id => entities?.find(entity => entity.id === id)).filter(Boolean) as unknown as T[],
    [entities, entityIds]
  )

  return { entities: orderedEntities, isError, error, isLoading, isSuccess: isSuccess || entityIds.length === 0 }
}
