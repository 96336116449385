import { Divider, TextField, Typography, type TextFieldProps } from '@mui/material'
import { type EntityOrElement } from '@tunasong/schemas'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '../../styles.js'
import { TagList } from '../../tags/index.js'
import { capitalize } from '@tunasong/models'

export interface ElementFormProps<T> {
  className?: string
  element?: Partial<T>
  onChange(element: Partial<T>): void
  onClose?(): void
}

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: theme.spacing(2),
  },

  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export const ElementForm = <T extends EntityOrElement = EntityOrElement>(props: ElementFormProps<T>) => {
  const { className, element, onClose, onChange } = props
  const { classes } = useStyles()

  const setField = useCallback(
    <K extends keyof T>(key: K) =>
      (value: T[K]) => {
        const val: Partial<T> = { [key]: value } as never
        onChange(val)
      },
    [onChange]
  )

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      if (ev.key === 'Enter' && onClose) {
        ev.preventDefault()
        onClose()
      }
    },
    [onClose]
  )
  const textFieldProps: Partial<TextFieldProps> = useMemo(
    () => ({
      size: 'small',
      variant: 'standard',
    }),
    []
  )
  const handleTextField = useCallback(
    (key: keyof T) =>
      ({ target: { value } }: { target: { value: string } }) =>
        setField(key)(value as never),
    [setField]
  )

  if (!element) {
    return null
  }
  const { name, description, tags, type } = element

  return (
    <div className={classNames(className, classes.root)}>
      <Typography variant="caption">Type: {capitalize(type ?? 'Unknown')}</Typography>
      <TextField
        {...textFieldProps}
        className={classes.field}
        label="Name"
        value={name ?? ''}
        autoFocus={true}
        onKeyDown={handleKeyDown}
        onChange={handleTextField('name')}
      />
      <TextField
        {...textFieldProps}
        className={classes.field}
        label="Description"
        multiline={true}
        value={description ?? ''}
        onChange={handleTextField('description')}
      />
      <Divider>
        <Typography variant="caption">Tags</Typography>
      </Divider>
      <TagList edit={false} tags={tags} onChange={setField('tags')} />
    </div>
  )
}

export default ElementForm
