import type { UserConfig } from '@tunasong/manifest'
import manifest from '@tunasong/manifest'
import { logger } from '@tunasong/models'
import { useCallback, useEffect, useRef } from 'react'
import invariant from 'tiny-invariant'
import { profilesApi } from '../api/profiles.js'
import type { UserConfigService } from '../configuration/config-service.js'
import { useStore } from '../configure-store.js'
import { features } from '../features/index.js'
import { useProfile } from './profile.js'
import { useSelector } from './selector.hook.js'
import { useThunkDispatch } from './thunk-dispatch.hook.js'

type PluginConfig<T extends keyof UserConfig['plugins']> = UserConfig['plugins'][T]

/**
 * Async user configuration service. This loads configuration async, so clients that must
 * have updated information should use this and wait for the promise to resolve.
 *
 * Others can use the synchronous version(s) that look like useState() hooks.
 */

let readyResolveFn: () => void | undefined
const readyPromise = new Promise<void>(resolve => {
  readyResolveFn = resolve
})

export const useAsyncUserConfigService = (): UserConfigService => {
  /** Currently we use the profile to store configuration */
  const profile = useProfile()
  const [updateProfile] = profilesApi.useUpdateProfileMutation()
  const dispatch = useThunkDispatch()
  const { getState } = useStore()

  const currentConfig = useSelector(state => state.config.userConfig)
  const configLoaded = useSelector(state => state.config.userConfigLoaded)
  const currentUserId = useSelector(state => state.user?.userId)

  // Initial set of config from profile
  useEffect(() => {
    if (!profile?.config || configLoaded || !readyResolveFn) {
      return
    }
    invariant(readyPromise instanceof Promise, 'readyPromiseRef.current is not a promise')
    invariant(readyResolveFn, 'readyResolveFn is not set')

    const config = manifest.userConfig.safeParse(profile.config)
    if (config.success) {
      dispatch(features.config.actions.updateUserConfig(config.data))
    } else {
      logger.error('Invalid user config', { config: profile.config })
      // if we have an invalid configuration, we'll just drop it
      // and update the profile with the default configuration at the next change.
    }

    readyResolveFn()

    dispatch(features.config.actions.setUserConfigLoaded(true))
  }, [configLoaded, currentUserId, dispatch, profile?.config])

  const profileId = profile?.id

  /** Update the user config for the specified key */
  const updateUserConfigByKey = useCallback(
    <T extends keyof UserConfig>(key: T, value: UserConfig[T]) => {
      if (!profileId) {
        throw new Error(`Cannot set config for  without a profile`)
      }

      // Parse with Zod to ensure it's valid
      const newValue = manifest.userConfig.parse({
        ...currentConfig,
        [key]: value,
      })

      dispatch(features.config.actions.updateUserConfig(newValue))

      return updateProfile({ id: profileId, profile: { config: newValue } })
    },
    [currentConfig, dispatch, profileId, updateProfile]
  )
  const updateUserConfigPartial = useCallback(
    (value: Partial<UserConfig>) => {
      if (!profileId) {
        throw new Error(`Cannot set config for  without a profile`)
      }

      // Parse with Zod to ensure it's valid
      const newValue = manifest.userConfig.parse({ ...currentConfig, ...value })

      dispatch(features.config.actions.updateUserConfig(newValue))

      return updateProfile({ id: profileId, profile: { config: newValue } })
    },
    [currentConfig, dispatch, profileId, updateProfile]
  )

  const getUserConfig = useCallback(
    async <T extends keyof UserConfig>(key: T): Promise<UserConfig[T]> => {
      invariant(readyPromise instanceof Promise, 'readyPromise is not a promise')

      await readyPromise

      const userConfig = getState().config.userConfig
      invariant(userConfig, 'User config are not set')
      return userConfig[key]
    },
    [getState]
  )

  const updatePluginConfig = useCallback(
    async <T extends keyof UserConfig['plugins']>(pluginName: T, val: PluginConfig<T>) => {
      // First get the current value of "plugins", then update the plugin's value

      updateUserConfigByKey('plugins', { ...currentConfig.plugins, [pluginName]: val })
    },
    [currentConfig.plugins, updateUserConfigByKey]
  )

  const getPluginConfig = useCallback(
    async <T extends keyof UserConfig['plugins']>(pluginName: T) => {
      const config = await getUserConfig('plugins')
      return config[pluginName] ?? {}
    },
    [getUserConfig]
  )

  const updateUserConfig = useCallback(
    <T extends keyof UserConfig | Partial<UserConfig>>(
      keyOrValue: T,
      value?: T extends keyof UserConfig ? UserConfig[T] : never
    ) => {
      if (typeof keyOrValue === 'string') {
        return updateUserConfigByKey(keyOrValue, value)
      }
      return updateUserConfigPartial(keyOrValue)
    },
    [updateUserConfigByKey, updateUserConfigPartial]
  )

  // We need this to be stable
  const globalConfigService = useRef<UserConfigService>({
    getUserConfig,
    getPluginConfig,
    updateUserConfigByKey,
    updateUserConfig,
    updatePluginConfig,
  })

  useEffect(() => {
    globalConfigService.current.getUserConfig = getUserConfig
    globalConfigService.current.updateUserConfig = updateUserConfig
    globalConfigService.current.updateUserConfigByKey = updateUserConfigByKey
    globalConfigService.current.updatePluginConfig = updatePluginConfig
  }, [getUserConfig, updatePluginConfig, updateUserConfig, updateUserConfigByKey])

  return globalConfigService.current
}

/** Use function overloads to resolve the generics (thanks co-pilot) */
export function useUserConfig<T extends keyof UserConfig>(
  key: T
): [config: UserConfig[T], updateConfig: (value: UserConfig[T]) => void, hasLoaded: boolean]

export function useUserConfig<T extends keyof UserConfig | Partial<UserConfig>>(): [
  config: UserConfig,
  updateConfig: (keyOrValue: T, value?: T extends keyof UserConfig ? UserConfig[T] : never) => void,
  hasLoaded: boolean,
]

export function useUserConfig<T extends keyof UserConfig>(key?: T) {
  const userConfig = useSelector(state => state.config.userConfig)
  const hasLoaded = useSelector(state => state.config.userConfigLoaded)
  const { updateUserConfig, updateUserConfigByKey } = useAsyncUserConfigService()

  if (key) {
    const value = userConfig[key]
    // Explicitly assert the return type for the specific key case
    const updateConfig = (value: UserConfig[T]) => updateUserConfigByKey(key, value)
    return [value, updateConfig, hasLoaded]
  }
  // For the general case, we might need to adjust based on your UserConfig type structure
  // Assuming updateUserConfig can be used directly if no key is provided
  // Explicitly assert the return type for the general case
  return [userConfig, updateUserConfig, hasLoaded]
}
