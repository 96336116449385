import { type Entity } from '@tunasong/schemas'

export type SystemTag = 'sys:background-image' | 'sys:header-image' | 'sys:ai-chat' | 'sys:template' | 'sys:resource'

export const SystemTags = {
  /** Background Image */
  BackgroundImage: 'sys:background-image',
  /** Image that is used for the header in certain views */
  HeaderImage: 'sys:header-image',
  /** AI Chat entity */
  AIChat: 'sys:ai-chat',
  /** Template */
  Template: 'sys:template',
  /** Use for hidden resources, e.g., for imports*/
  Resource: 'sys:resource',
} satisfies Record<string, SystemTag>

export type Tag = SystemTag | string

/** * Entity is system entity, i.e., it is tagged with sys:* */
export const isTaggedSysEntity = (entity: Pick<Entity, 'tags'>, tag?: SystemTag) =>
  Boolean(entity.tags?.find(t => (tag ? t === tag : t.startsWith('sys:'))))

/** Currently we hide all system entities from several views, unless it's a template */
export const isHiddenSysEntity = (entity: Entity) =>
  Boolean(entity.tags?.find(t => t.startsWith('sys:') && t !== SystemTags.Template))
