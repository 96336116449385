import { Avatar, Box, Step, StepConnector, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { dayjs, type FeedItem } from '@tunasong/models'
import cn from 'classnames'
import { type FC, useEffect, useMemo, useState } from 'react'
import { useProfiles } from '@tunasong/redux'
import { UserAvatar } from '../profile/index.js'
import { useScroll } from './scroll.js'

import { makeStyles } from '../styles.js'

const useStyles = makeStyles()(theme => ({
  root: {
    overflow: 'auto',
  },
  avatar: {
    // left: -theme.spacing(),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  stepper: {
    padding: 0,
    paddingLeft: theme.spacing(0.5),
    backgroundColor: 'inherit',
  },
  stepConnector: {
    minHeight: theme.spacing(),
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    fontSize: 12,
  },
  highlight: {
    borderColor: theme.palette.success.light,
    borderStyle: 'dashed',
    borderWidth: 2,
  },
}))

export interface FeedProps {
  className?: string
  title?: string
  items: FeedItem[]
  maxItems?: number
  topRef?: React.RefCallback<HTMLDivElement>
}

export const Feed: FC<FeedProps> = props => {
  const { className, topRef, title, maxItems = Number.MAX_SAFE_INTEGER, items = [] } = props
  const { classes } = useStyles()

  const userIds = useMemo(() => items.map(i => i.userId), [items])
  const { profiles, getName } = useProfiles({ userIds })

  const showItems = useMemo(() => items.slice(0, maxItems), [items, maxItems])

  const { highlightId } = useScroll(items)

  /** @note we do this in order to avoid a strange race condition while rendering in useProfile */
  const [userIcons, setIcons] = useState<Record<string, FC>>()
  const DummyAvatar = useMemo(() => () => <Avatar className={classes.avatar} />, [classes.avatar])
  useEffect(() => {
    const icons: Record<string, FC> = {}
    for (const profile of profiles ?? []) {
      icons[profile.id] = () => <UserAvatar className={classes.avatar} userId={profile.userId} size="small" />
    }
    setIcons(icons)
  }, [classes.avatar, profiles])

  const stepConnector = useMemo(
    () => <StepConnector classes={{ lineVertical: classes.stepConnector }} />,
    [classes.stepConnector]
  )

  return (
    <div className={cn(className, classes.root)}>
      {title ? <Typography variant="subtitle1">{title}</Typography> : null}

      {userIcons && showItems.length > 0 ? (
        <Stepper className={classes.stepper} orientation="vertical" connector={stepConnector}>
          {showItems.map((item, idx) => (
            <Step
              key={idx}
              ref={idx === 0 ? topRef : null}
              // ref={item.id === activeId ? focusRef : null}
              active={true}
              className={cn({ [classes.highlight]: Boolean(item.id && item.id === highlightId) })}
            >
              <StepLabel StepIconComponent={userIcons[item.userId] ?? DummyAvatar}>
                <Box className={classes.label}>
                  <Typography variant="body2">{getName(item.userId)}</Typography>
                  <Typography variant="caption">{dayjs(item.createdAt).fromNow()}</Typography>
                </Box>
              </StepLabel>
              <StepContent>
                <Box className={classes.content}>{item.content}</Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Typography variant="caption">Messages will appear here - use the Chat box below</Typography>
      )}
    </div>
  )
}

export default Feed
