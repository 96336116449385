import { ZodSchema } from 'zod'

/** Core entities */
import {
  audioFeatureSchema,
  audioSchema,
  isAudio,
  isAudioChordsFeature,
  isAudioFeature,
  isAudioFeatureMap,
  isAudioSeparation,
  type Audio,
  type AudioChordsFeature,
  type AudioFeature,
  type AudioFeatureUrls,
  type AudioMedia,
} from './audio.js'
import { chatSchema, isChat, type Chat } from './chat.js'
import { commentSchema, isComment, type Comment } from './comment.js'
import { imageSchema, isImage, type Image, type ImageElement } from './image.js'
import { isNote, noteSchema, type Note } from './note.js'
import { isPage, pageSchema, type Page } from './page.js'
import { isPdf, pdfSchema, type PDF } from './pdf.js'
import { isProfile, profileSchema, profileServerSchema, type Profile, type ProfileServerSchema } from './profile.js'
import { isTodo, todoSchema, type Todo } from './todo.js'
import { isVideo, videoSchema, type Video, type VideoMedia } from './video.js'
import { tableSchema, isTable, type Table } from './table.js'
import { baseEntitySchema } from '../entity.js'

/** Exports */

export {
  audioFeatureSchema,
  audioSchema,
  chatSchema,
  commentSchema,
  imageSchema,
  tableSchema,
  isAudio,
  isTable,
  isAudioChordsFeature,
  isAudioFeature,
  isAudioFeatureMap,
  isAudioSeparation,
  isChat,
  isComment,
  isImage,
  isNote,
  isPage,
  isPdf,
  isProfile,
  isTodo,
  isVideo,
  noteSchema,
  pageSchema,
  pdfSchema,
  profileSchema,
  profileServerSchema,
  todoSchema,
  videoSchema,
  type Audio,
  type AudioChordsFeature,
  type AudioFeature,
  type AudioFeatureUrls,
  type AudioMedia,
  type Chat,
  type Comment,
  type Image,
  type ImageElement,
  type Note,
  type Page,
  type PDF,
  type Profile,
  type ProfileServerSchema,
  type Todo,
  type Video,
  type Table,
  type VideoMedia,
}

/** Add new core entity types here */
export const coreEntityNames = [
  'audio',
  'chat',
  'comment',
  'image',
  'page',
  'pdf',
  'profile',
  'todo',
  'video',
  'note',
  // Table types
  'table',
  'tr',
  'th',
  'td',
  'thead',
  'tbody',
] as const
export type CoreEntityName = (typeof coreEntityNames)[number]

const schemas = {
  audio: audioSchema,
  page: pageSchema,
  profile: profileSchema,
  image: imageSchema,
  chat: chatSchema,
  todo: todoSchema,
  comment: commentSchema,
  video: videoSchema,
  pdf: pdfSchema,
  note: noteSchema,
  table: tableSchema,
  tr: baseEntitySchema,
  th: baseEntitySchema,
  td: baseEntitySchema,
  thead: baseEntitySchema,
  tbody: baseEntitySchema,
} satisfies Record<CoreEntityName, ZodSchema<unknown>>

export default schemas
