import { logger } from '@tunasong/models'
import { runEditorCommand, useEditor, useGlobalAppContext } from '@tunasong/plugin-lib'
import { useUserConfig } from '@tunasong/redux'
import { useEffect } from 'react'

/** Run the command on mount, or when any of the parameters change */
export const useRunEditorCommand = (props: { enterCommandId?: string; leaveCommandId?: string } | null) => {
  const { leaveCommandId, enterCommandId } = props ?? {}
  const [maturity] = useUserConfig('featureMaturity')
  const editor = useEditor({ allowNull: true })
  const globalContext = useGlobalAppContext()
  const plugins = editor?.plugins

  useEffect(() => {
    if (!(enterCommandId || leaveCommandId)) {
      return
    }
    if (!(editor && plugins)) {
      logger.warn(`Cannot run editor command without an editor context`, enterCommandId, leaveCommandId)
      return
    }
    if (enterCommandId) {
      runEditorCommand({ commandId: enterCommandId, plugins, editor, maturity, globalContext })
    }

    return () => {
      if (leaveCommandId) {
        runEditorCommand({ commandId: leaveCommandId, plugins, editor, maturity, globalContext })
      }
    }
  }, [editor, enterCommandId, plugins, globalContext, leaveCommandId, maturity])
}
