import { Box, LinearProgress, List, Typography } from '@mui/material'
import {
  defaultSortOptions,
  isFolder,
  isPersistedEntity,
  matchAny,
  type FilterFunc,
  type SortOptions,
} from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import { useDeviceState, useEntityUtil } from '@tunasong/redux'
import { entityTypeNames, isEntity, type Entity, type EntityType, type Persisted } from '@tunasong/schemas'
import { useCallback, useRef, useState, type FC, type MouseEvent } from 'react'
import { useDrop } from 'react-dnd'
import invariant from 'tiny-invariant'
import { ConfirmDialog } from '../dialog/index.js'
import { type EntityListProps } from '../entity/entity-list.js'
import { ListSkeleton } from '../entity/list-skeleton.js'
import { reorder } from '../entity/reorder.js'
import SortFilter from '../entity/sort-filter.js'
import { useEntitySort } from '../hooks/sort.js'
import { useNavigateToEntity } from '../navigation/navigate.js'
import { DROP_BOTTOM, type DropTarget } from './constants.js'
import DndDivider from './dnd-divider.js'
import { DraggableListItem } from './dnd-list-item.js'

export interface DndListProps extends Omit<EntityListProps, 'onChange'> {
  header?: boolean
  filter?: FilterFunc

  isLoading?: boolean

  navigate?: boolean

  sortHeader?: boolean

  // The key to use for sorting, filtering etc.. If provided, the options will be persisted on the device for this key.
  optionsKey?: string

  isDropTarget?(props: { source?: Entity; target: Entity }): boolean

  onChange?(elements: Persisted<Entity>[]): void
}

/** Accept drop into folders, but nothing else for now */

export const DndList: FC<DndListProps> = props => {
  const {
    entities,
    actions = true,
    size = 'small',
    selected = [],
    navigate = true,
    isLoading = false,
    optionsKey = 'default',
    sortHeader = true,
    onOpen,
    onChange,
  } = props
  const { moveEntity } = useEntityUtil()
  const navigateToEntity = useNavigateToEntity()
  const plugins = usePlugins('all')

  const isDropTargetDefault = useCallback(
    ({ source, target }: { source?: Entity; target: Entity }) => {
      const plugin = plugins?.find(p => p.type === target.type)
      const allowChildrenTypes = plugin?.allowChildrenTypes

      if (!source?.type) {
        return false
      }
      let sourceMatch = false
      if (typeof allowChildrenTypes === 'boolean') {
        sourceMatch = allowChildrenTypes
      } else {
        sourceMatch = Boolean(allowChildrenTypes?.includes(source.type))
      }

      const targetMatch = matchAny(isFolder, () => Boolean(allowChildrenTypes))(target)

      return sourceMatch && targetMatch
    },
    [plugins]
  )
  const isDropTarget: typeof isDropTargetDefault = props.isDropTarget ?? isDropTargetDefault

  /** Set to confirm message */
  const moveData = useRef<[Persisted<Entity>, Persisted<Entity>] | null>(null)
  const [moving, setMoving] = useState(false)
  const [confirm, setConfirm] = useState<null | string>(null)

  const handleCancel = useCallback(async () => setConfirm(null), [])

  const handleMove = useCallback(async () => {
    invariant(moveData.current, 'moveData is not set')
    invariant(moveData.current, 'moveData is not set')

    const [entity, newParent] = moveData.current
    setMoving(true)
    moveEntity({ entity, newParent })
    setMoving(false)
    moveData.current = null
    setConfirm(null)
  }, [moveEntity])

  const handleDropped = useCallback(
    (source: Entity, target: DropTarget) => {
      if (isPersistedEntity(target) && isPersistedEntity(source) && isDropTarget({ source, target })) {
        setConfirm(`Move ${source.type} "${source.name}" to ${target.type} "${target.name}?"`)
        moveData.current = [source, target]
        return
      }

      if (onChange) {
        /** Re-order the list */
        const targetIndex = isEntity(target) ? entities.findIndex(e => e.id === target.id) : entities.length - 1
        const sourceIndex = entities.findIndex(e => e.id === source.id)
        onChange(reorder(entities, sourceIndex, targetIndex))
      }
    },
    [entities, isDropTarget, onChange]
  )

  const handleOpen = useCallback(
    (element: Persisted<Entity>) => (ev: MouseEvent) => {
      const navigationMode = ev.shiftKey ? 'dialog' : 'page'
      if (onOpen) {
        onOpen(element, { navigationMode })
      } else if (navigate) {
        navigateToEntity(element, { navigationMode })
      }
    },
    [navigate, navigateToEntity, onOpen]
  )

  const [{ isHovering: isHoveringBottom }, dropBottomRef] = useDrop({
    accept: entityTypeNames as never,
    drop: item => {
      handleDropped(item, DROP_BOTTOM)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canDrop: (item: Entity) => true,
    collect: monitor => ({
      isHovering: monitor.isOver({ shallow: true }), // canDrop(monitor.getItem()) &&,
    }),
  })

  const [sortOptions = defaultSortOptions, setSortOptions] = useDeviceState<SortOptions>(`entities.${optionsKey}.sort`)
  const [filter = [], setFilter] = useDeviceState<EntityType[]>(`entities.${optionsKey}.filter`)

  const types = [...new Set([...entities.map(p => p.type)])].sort()
  const filteredEntities = (filter.length > 0 ? entities.filter(e => filter.includes(e.type)) : entities).filter(
    e => !e.trash
  )
  const entitiesSorted = useEntitySort(filteredEntities, sortOptions)

  if (isLoading && (!entities || entities.length === 0)) {
    return <ListSkeleton />
  }
  if (entities.length === 0) {
    return (
      <Typography variant="subtitle1" sx={{ color: 'action.disabled' }}>
        No items
      </Typography>
    )
  }

  return (
    <>
      {sortHeader ? (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <SortFilter
            {...sortOptions}
            onSort={setSortOptions}
            filterTypes={types}
            onFilter={setFilter}
            defaultFilter={filter}
          />
        </Box>
      ) : null}
      {isLoading ? <LinearProgress /> : null}
      <List>
        {entitiesSorted.map(element => (
          <DraggableListItem
            key={element.id}
            size={size}
            selected={selected.includes(element)}
            actions={actions}
            element={element}
            onDropped={handleDropped}
            isDropTarget={isDropTarget}
            onClick={handleOpen(element)}
          />
        ))}
        {isHoveringBottom ? <DndDivider /> : null}
        <Box sx={{ height: 4 }} ref={dropBottomRef as never} />
        <ConfirmDialog
          open={Boolean(confirm)}
          busy={moving}
          title={'Confirm move'}
          text={confirm ?? ''}
          onCancel={handleCancel}
          onConfirm={handleMove}
        />
      </List>
    </>
  )
}
