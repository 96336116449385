import { Box, type BoxProps, LinearProgress, Typography } from '@mui/material'
import { colors } from '@mui/material'
import { type Entity } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'
import classNames from 'classnames'
import { type FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { type Accept, type FileWithPath, useDropzone } from 'react-dropzone'
import { makeStyles } from '../styles.js'
import { useUploadFiles } from '../upload/upload-files.hook.js'
import { isMobileDevice } from '../browser/index.js'
import { useStableRef } from '../hooks/stable-ref.js'
import { logger } from '@tunasong/models'

export interface DropzoneProps extends BoxProps {
  className?: string
  parentId: string | null
  /** Accepted mime-types. */
  accept: Accept
  /** Set these tags to the uploaded entities */
  tags?: string[]
  /** Content hint in the drop zone */
  contentHint?: string
  /** Show zone, even when not hovering. @default false */
  showZone?: boolean
  /** When uploadOnClick is true, show a separate click zone */
  showClickZone?: boolean
  /** Uploaded entities are private and do not inherit ACLs */
  isPrivate?: boolean
  uploadOnClick?: boolean
  /** Max number of files to drop. Set to 50 */
  maxFiles?: number
  /** Invoked when file is being uploaded */
  onUploading?(): void
  /** When the file has been uploaded, onUploaded is invoked */
  onUploaded?(uploaded: Persisted<Entity>): void
}

const useStyles = makeStyles()(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '&:focus': {
      outline: 'none',
    },
    mb: 2,
    /** @note using cursor here makes the whole container "active" on click/tap */
    // cursor: 'pointer',
  },
  caption: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    color: theme.palette.grey[500],
  },
  dropzoneActive: {
    borderColor: `${theme.palette.primary.main} !important`,
  },
  dragReject: {
    borderColor: `${theme.palette.divider} !important`,
  },
  dragAccept: {
    borderColor: `${colors.green[500]} !important`,
    color: `${colors.green[500]} !important`,
  },
  dropzone: {
    marginTop: theme.spacing(),
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.grey[300],
    borderWidth: theme.spacing(0.5),
    borderStyle: 'dashed',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  uploadZone: {
    display: 'flex',
    color: theme.palette.action.disabled,
    flex: 1,
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

/** Upload a file to S3, and return an entity that references it  */
export const DropZone: FC<DropzoneProps> = props => {
  const {
    className,
    parentId,
    accept,
    children,
    showZone = false,
    uploadOnClick = false,
    isPrivate = false,
    contentHint = 'file',
    maxFiles = 50,
    title,
    showClickZone,
    onUploaded,
    onUploading,
    tags,
    ...restProps
  } = props
  const { classes } = useStyles()

  const { uploadFiles, uploading, handleChange } = useUploadFiles({ isPrivate, onUploaded, tags })

  const onUploadingRef = useStableRef(onUploading)
  useEffect(() => {
    if (uploading && onUploadingRef.current) {
      onUploadingRef.current()
    }
  }, [onUploadingRef, uploading])

  const onDrop = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      uploadFiles({ files: acceptedFiles, parentId }).catch(err => {
        logger.error('Failed to upload files', err)
      })
    },
    [parentId, uploadFiles]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxFiles,
    accept,
    // we handle the click event ourselves
    noClick: true,
  })

  const uploadRef = useRef<HTMLInputElement | null>(null)
  const handleClick = useCallback(() => {
    // If we don't show the zone we should not respond to clicks
    if (showZone || uploadOnClick) {
      uploadRef.current?.click()
    }
  }, [showZone, uploadOnClick])

  /** We don't want the key handler because it has some bugs in it */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onKeyDown, ...rootProps } = useMemo(() => getRootProps(), [getRootProps])
  const acceptStr =
    Object.keys(accept).join(',') +
    Object.values(accept)
      .flatMap(v => v)
      .join(',')
  const dropZoneText = title ?? (isMobileDevice() ? 'Tap to upload' : 'Drop files or click to upload')

  return (
    <>
      <Box
        {...rootProps}
        className={classNames(className, classes.root, {
          [classes.dropzone]: showZone || isDragActive,
          [classes.dropzoneActive]: showZone || isDragActive,
          [classes.dragReject]: isDragReject,
          [classes.dragAccept]: isDragAccept,
        })}
        title={uploadOnClick ? dropZoneText : undefined}
        onClick={uploadOnClick && !showClickZone ? handleClick : undefined}
        {...restProps}
      >
        <input
          {...getInputProps()}
          ref={uploadRef}
          disabled={uploading}
          type="file"
          multiple={true}
          accept={acceptStr}
          hidden
          onChange={handleChange(parentId)}
        />

        <Typography variant="caption" className={classes.caption}>
          {isDragAccept && `All file(s) accepted. Drop to add ${contentHint}`}
          {isDragReject && 'Some files may be rejected'}
        </Typography>
        {!isDragActive ? <Box className={classes.content}>{children}</Box> : null}
        {/* Click zone */}
        {uploadOnClick && showClickZone && !isDragActive ? (
          <Box className={classes.uploadZone} onClick={handleClick}>
            <Typography variant="caption">{dropZoneText}</Typography>
          </Box>
        ) : null}
      </Box>
      {uploading ? <LinearProgress /> : null}
    </>
  )
}

export default DropZone
