import { useUserConfig } from '@tunasong/redux'
import { useMemo } from 'react'
import { useGlobalAppContext } from '../app-context.js'
import type { Command } from '../plugin-types.js'
import { getGlobalCommands } from '../plugin/command.js'
import { usePlugins } from './editor-plugins.js'

/** Commands that are available globally */
export const useGlobalCommands = ({ text }: { text?: string }): Command[] => {
  const [maturity] = useUserConfig('featureMaturity')
  const plugins = usePlugins('all')
  const globalContext = useGlobalAppContext()

  const commands = useMemo(
    () => (plugins ? getGlobalCommands({ text, plugins, globalContext, maturity }) : []),
    [globalContext, maturity, plugins, text]
  )

  return commands
}
