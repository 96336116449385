import type { Persisted } from '@tunasong/schemas'
import { type CoreElement, type Entity, isCoreElement } from '@tunasong/schemas'
import { type TunaElement } from '../element/editor.js'

export interface EntityLink extends CoreElement {
  type: 'entitylink'
  ref: Pick<Persisted<Entity>, 'type' | 'name' | 'description'> & { id: string }
  // Link has errors, e.g., not accessible
  error?: boolean
}

export function isEntityLink(node?: unknown): node is TunaElement<EntityLink> {
  return Boolean(isCoreElement(node) && node.type === 'entitylink')
}

export const toEntityLink = ({ id, type, description, name }: Persisted<Entity>): EntityLink => ({
  type: 'entitylink',
  ref: {
    id,
    type,
    description,
    name,
  },
  children: [{ text: '' }],
})
