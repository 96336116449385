import { ThemeProvider, createTheme, useTheme } from '@mui/material'
import {
  GlobalStyles,
  createMakeStyles,
  type Tss,
  type CSSInterpolation,
  type CSSObject,
  type Css,
  type Cx,
  type CxArg,
  type tss,
} from 'tss-react'

export interface TTunaTheme {
  /** Optional so we don't crash without the special theme */
  layers?: {
    backgroundColor: string
  }
  notes: {
    main: string
    outOfScale: string
    inScale: string
  }
}

/** Add layer background */
declare module '@mui/material/styles' {
  interface Theme extends TTunaTheme {}
  interface ThemeOptions extends Partial<TTunaTheme> {}
}

// material-ui users can pass in useTheme imported like: import { useTheme } from "@mui/material/styles";
const { makeStyles } = createMakeStyles({ useTheme })

/** @note we re-export types here as we got into issue when using both moduleResolution nodenext and node (to work around old incompatible libs such as react-focus-lock) */
export {
  GlobalStyles,
  ThemeProvider,
  createMakeStyles,
  createTheme,
  makeStyles,
  type CSSInterpolation,
  type CSSObject,
  type Css,
  type Cx,
  type CxArg,
  type Tss,
  type tss,
}
