import { IconButton, type IconButtonProps, type Theme } from '@mui/material'
import { Close as CloseIcon } from '@tunasong/icons'
import cn from 'classnames'
import React, { useCallback, type MouseEvent, type MouseEventHandler } from 'react'
import { makeStyles } from '../styles.js'

export interface CloseButtonProps extends IconButtonProps {
  onClose: (ev: MouseEvent) => void
}

const useStyles = makeStyles()((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    zIndex: 1,
  },
}))

export const CloseButton = (props: CloseButtonProps) => {
  const { className, onClose, ...restProps } = props
  const { classes } = useStyles()
  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (ev: MouseEvent) => {
      if (!onClose) {
        return
      }
      onClose(ev)
    },
    [onClose]
  )

  if (!onClose) {
    return null
  }

  return (
    <IconButton
      {...restProps}
      aria-label="close"
      className={cn(className, classes.closeButton)}
      tabIndex={-1}
      onClick={handleClose}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default CloseButton
