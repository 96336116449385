import { entitySort, type FilterFunc, type SortOptions } from '@tunasong/models'
import { type Entity, type Persisted } from '@tunasong/schemas'

export const useEntitySort = <T extends Entity>(
  entities: Persisted<T>[],
  sortOptions: SortOptions,
  filter?: FilterFunc
) => {
  const sortedEntities = entitySort({
    entities,
    sortOptions,
    filter,
  })

  return sortedEntities as Persisted<T>[]
}
