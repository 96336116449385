import { List, ListItem, type ListProps, MenuList } from '@mui/material'
import type { Persisted } from '@tunasong/schemas'
import { type Entity } from '@tunasong/schemas'
import React, { useCallback, useMemo } from 'react'
import { useFavorites } from '../favorites/favorites.hook.js'
import type { NavigationOptions } from '../navigation/navigate.js'
import { EntityListItem } from './entity-list-item.js'

export interface EntityListProps extends ListProps {
  className?: string

  entities: Persisted<Entity>[]
  selected?: Persisted<Entity>[]
  controls?: boolean
  size?: 'tiny' | 'small' | 'large'
  placeholder?: string

  actions?: boolean
  showBreadcrumbs?: boolean

  ListComponent?: typeof List | typeof MenuList
  onOpen?(entity: Persisted<Entity>, options: NavigationOptions): void
}

export const EntityList = React.forwardRef((props: EntityListProps, ref: React.Ref<HTMLUListElement>) => {
  const {
    className,
    entities,
    actions,
    selected = [],
    size = 'small',
    showBreadcrumbs = false,

    ListComponent = List,
    onOpen,
    ...restProps
  } = props

  const { favorites } = useFavorites()

  const handleOpen = useCallback(
    (entity: Persisted<Entity>) => (ev: React.MouseEvent<HTMLLIElement>) => {
      if (!onOpen) {
        return
      }
      ev.preventDefault()
      ev.stopPropagation()
      const navigationMode = ev.shiftKey ? 'dialog' : 'page'
      onOpen(entity, { navigationMode })
    },
    [onOpen]
  )

  const items = useMemo(
    () =>
      entities
        .filter(e => !e.trash)
        .map((element, index) => {
          const favorite = favorites.some(f => f.id === element.id)
          return (
            <EntityListItem
              key={index}
              size={size}
              favorite={favorite}
              actions={actions}
              selected={selected.includes(element)}
              element={element}
              showBreadcrumbs={showBreadcrumbs}
              onClick={handleOpen(element)}
            />
          )
        }),
    [actions, entities, favorites, handleOpen, selected, showBreadcrumbs, size]
  )

  return (
    <ListComponent ref={ref} className={className} {...restProps}>
      {items?.length ?? 0 > 0 ? items : <ListItem disabled>{restProps.placeholder}</ListItem>}
    </ListComponent>
  )
})
