import { z } from 'zod'
import coreSchemas from './core/entities/index.js'
import { baseEntitySchema } from './core/entity.js'
import { type CoreElement } from './core/slate/slate-element.js'
import { entityTypeNames } from './entity-types.js'
import { extensionSchemas } from './extensions/index.js'
import { storageSchema } from './core/index.js'

/** Schemas  */
export const schemas = {
  /* * @todo implement these */

  section: baseEntitySchema.extend({ type: z.literal('section') }).passthrough(),
  poll: baseEntitySchema.extend({ type: z.literal('poll') }).passthrough(),
  sketch: baseEntitySchema.extend({ type: z.literal('sketch') }).passthrough(),
  amp: baseEntitySchema.extend({ type: z.literal('amp') }).passthrough(),
  bass: baseEntitySchema.extend({ type: z.literal('bass') }).passthrough(),
  event: baseEntitySchema.extend({ type: z.literal('event') }).passthrough(),
  drawing: baseEntitySchema.extend({ type: z.literal('drawing') }).passthrough(),
  drums: baseEntitySchema.extend({ type: z.literal('drums') }).passthrough(),
  facade: baseEntitySchema.extend({ type: z.literal('facade') }).passthrough(),
  folder: baseEntitySchema.extend({ type: z.literal('folder') }).passthrough(),
  game: baseEntitySchema.extend({ type: z.literal('game') }).passthrough(),
  guitar: baseEntitySchema.extend({ type: z.literal('guitar') }).passthrough(),
  mic: baseEntitySchema.extend({ type: z.literal('mic') }).passthrough(),
  piano: baseEntitySchema.extend({ type: z.literal('piano') }).passthrough(),
  playlist: baseEntitySchema.extend({ type: z.literal('playlist') }).passthrough(),
  room: baseEntitySchema.extend({ type: z.literal('room') }).passthrough(),
  score: baseEntitySchema.extend({ type: z.literal('score') }).passthrough(),
  tab: baseEntitySchema.extend({ type: z.literal('tab') }).passthrough(),
  synth: baseEntitySchema.extend({ type: z.literal('synth') }).passthrough(),
  tracker: baseEntitySchema.extend({ type: z.literal('tracker') }).passthrough(),
  trackerdata: baseEntitySchema.extend({ type: z.literal('trackerdata') }).passthrough(),

  // Stored entities
  evernote: baseEntitySchema.extend({ type: z.literal('evernote'), storage: storageSchema }).passthrough(),

  /** Core schemas */
  ...coreSchemas,
  /** Extension schemas */
  ...extensionSchemas,
}

/** Full Schema that from `baseEntitySchema` that contains type field */
export const entitySchema = baseEntitySchema.extend({
  type: z.enum(entityTypeNames),
})

/** @todo move these out of this file */

/**
 * We have the following challenge. Some of the entities we want to be able to embed
 * in the slate editor (e.g., audio) others not (e.g., folder). So some of them
 * must be compatible with SlateElement, which means:
 *  1) index signature
 *  2) children
 *
 * We don't want to have an index signature on all of the entities for type safety.
 */
export type Entity = z.infer<typeof entitySchema>
export function isEntity<T>(node: T): node is Entity & T {
  return entitySchema.safeParse(node).success
}

export type TEntity<T> = Entity & T

/** @todo do we need this? */
export type EntityOrElement = CoreElement | Entity
