import { Box, type BoxProps } from '@mui/material'
import type { Image, Persisted } from '@tunasong/schemas'
import { type FC } from 'react'
import { makeStyles } from '../styles.js'
import { useImage } from '../hooks/image.hook.js'

interface HeaderImageProps extends BoxProps {
  image: Persisted<Image>
}

const useStyles = makeStyles()(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    maxHeight: 200,
    objectFit: 'cover',
    border: 'none',
    maskImage: 'linear-gradient(to bottom, black 70%, transparent 100%)', // Apply gradient mask
  },
}))

export const HeaderImage: FC<HeaderImageProps> = props => {
  const { classes } = useStyles()
  const { image, sx, ...boxProps } = props
  const url = useImage(image, 'large')

  return (
    <Box {...boxProps} sx={{ backgroundColor: theme => theme.palette.background.paper, opacity: 0.4, ...sx }}>
      <img className={classes.root} src={url} />
    </Box>
  )
}
