import { Tab as BaseTab, tabClasses } from '@mui/base'
import { Button, buttonClasses, IconButton, styled, type ButtonProps } from '@mui/material'
import { Close } from '@tunasong/icons'
import { forwardRef } from 'react'

interface CustomTabProps extends Omit<ButtonProps, 'onChange'> {
  label: string
  value: string
  onClose: (value: string) => void
}

const CustomGroupTab = forwardRef<HTMLButtonElement, CustomTabProps>(
  ({ onClose, label = 'Untitled', value, ...restProps }, ref) => (
    <Button
      ref={ref}
      LinkComponent={'a'}
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: theme => theme.spacing(0.5) }}
      {...restProps}
    >
      {label}
      <IconButton
        onClick={ev => {
          ev.stopPropagation()
          onClose?.(value)
        }}
        sx={{ ml: 1 }}
      >
        <Close sx={{ fontSize: 12 }} />
      </IconButton>
    </Button>
  )
)

const GroupTab = (props: CustomTabProps) => <BaseTab {...props} slots={{ root: CustomGroupTab }} />

export const ContentGroupTab = styled(GroupTab)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: '0.875rem',
  borderRadius: 0,
  textTransform: 'none',
  backgroundColor: 'transparent',
  lineHeight: '1.2',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',

  [`&.${tabClasses.selected}`]: {
    fontWeight: 'bold',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },

  [`&.${buttonClasses.disabled}`]: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}))
