import { isEntity } from '@tunasong/schemas'
import { type Entity } from '@tunasong/schemas'

export const pollTypes = ['one-of-many', 'many-of-many'] as const
export type PollType = (typeof pollTypes)[number]

export interface PollOption {
  name: string
}

/** Add with other response types */
export type Response = number | Record<number, boolean>

export interface Poll extends Entity {
  type: 'poll'
  parentId?: string
  pollType?: PollType
  name: string
  pollOptions: PollOption[]

  /** The userIds of invited users. When invited, a notification is sent to the user. */
  invited?: string[]
  /** userId => response map */
  responses: Record<string, Response>

  finalOption?: Response

  /** Final option has been chosen and poll is closed */
  closed?: boolean

  /** Allow changes to given answers(s). Defaults to false. */
  allowChange?: boolean
}

export interface OneOfManyPoll extends Poll {
  pollType: 'one-of-many'
  responses: Record<string, number>
  finalOption?: number
}

export interface ManyOfManyPoll extends Poll {
  pollType: 'many-of-many'
  /** The maximum number of responses */
  responseQuota?: number
  responses: Record<string, Record<number, boolean>>
  finalOption?: Record<number, boolean>
}

export function isPoll(node: unknown): node is Poll {
  return Boolean(isEntity(node) && node.type === 'poll')
}

export function isOneOfMenyPoll(node: unknown): node is OneOfManyPoll {
  return Boolean(
    isEntity(node) && node.type === 'poll' && (node as unknown as OneOfManyPoll)?.pollType === 'one-of-many'
  )
}

export function isManyOfManyPoll(node: unknown): node is ManyOfManyPoll {
  return Boolean(
    isEntity(node) && node.type === 'poll' && (node as unknown as ManyOfManyPoll)?.pollType === 'many-of-many'
  )
}
