import { Box, type BoxProps } from '@mui/material'
import { ErrorOutline, Link as LinkIcon } from '@tunasong/icons'
import { getPlugin, usePlugins } from '@tunasong/plugin-lib'
import { type Entity, type Persisted } from '@tunasong/schemas'
import { useCallback, useMemo, useRef, type FC } from 'react'
import { CMD, SHIFT } from '../hotkeys/shortcut.js'
import { useNavigateToEntity } from '../navigation/navigate.js'
import { usePreloadOnHover } from './preload-on-hover.js'

export interface EntityLinkProps extends BoxProps {
  entity: Pick<Persisted<Entity>, 'id' | 'name' | 'type'>
  error?: boolean
  selected?: boolean
}

export const EntityLink: FC<EntityLinkProps> = props => {
  const { entity, error, ...restProps } = props

  const name = entity?.name ?? 'Untitled'
  const id = entity?.id

  const containerRef = useRef<HTMLDivElement>(null)
  usePreloadOnHover({ entityId: id, containerEl: containerRef.current })

  const navigateToEntity = useNavigateToEntity()

  const handleClick = useCallback(
    (ev: React.MouseEvent) => {
      if (!id) {
        return
      }
      ev.preventDefault()

      // Navigate may trigger compute intensive stuff, so allow e.g., animations to finish
      setTimeout(() => {
        if (ev.metaKey && ev.shiftKey) {
          return navigateToEntity(entity, { navigationMode: 'drawer' })
        }
        if (ev.shiftKey) {
          return navigateToEntity(entity, { navigationMode: 'dialog' })
        }
        if (ev.ctrlKey || ev.metaKey) {
          return navigateToEntity(entity, { navigationMode: 'panel' })
        }
        return navigateToEntity(entity, { navigationMode: 'page' })
      }, 0)
    },
    [id, navigateToEntity, entity]
  )

  const plugins = usePlugins('all')

  const plugin = useMemo(() => getPlugin(entity, plugins), [entity, plugins])
  const Icon = error ? ErrorOutline : plugin?.icon ?? LinkIcon
  const color = error ? 'red' : plugin?.color
  const title = `Click to open page, ${CMD} Click to open panel, ${CMD}+${SHIFT} Click to open drawer`

  return (
    <Box
      {...restProps}
      title={title}
      sx={{
        ...restProps.sx,
        userSelect: 'none',
        color: theme => (error ? theme.palette.error.main : theme.palette.primary.main),
        display: 'inline-flex',
        alignItems: 'baseline',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      }}
      ref={containerRef}
      onClick={handleClick}
    >
      <Icon sx={{ color, alignSelf: 'center' }} />
      <Box sx={{ ml: 0.5, mr: 0.5 }} component="span">
        {name}
      </Box>
    </Box>
  )
}

export default EntityLink
