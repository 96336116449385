/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from '../styles.js'

export const useCommonStyles = makeStyles()(theme => ({
  /** These are for small controls - e.g., play buttons and such */
  control: {
    color: theme.palette.grey[500],
  },
  controlText: {
    color: theme.palette.grey[500],
    fontSize: 13,
    whiteSpace: 'nowrap',
  },
  controlTextRegular: {
    color: theme.palette.grey[500],
  },
  controlInput: {
    fontSize: 13,
  },
  controlInputContainer: {
    padding: theme.spacing(0.5),
  },
  /** Use for control IconButtons */
  controlLink: {
    '&:hover': {
      textDecoration: 'underline',
      // color: theme.palette.primary.main,
    },
  },
  controlButton: {
    '& svg': {
      fontSize: 16,
    },
    color: theme.palette.grey[500],
  },
  /** Actions */
  controlAction: {
    left: `0px !important`,
  },
  controlSecondaryAction: {
    right: `0px !important`,
  },
  controlCenter: {
    justifyContent: 'center',
  },
  toggleIcon: {
    height: 28,
    width: 28,
  },
}))
