/**
 * Custom fetch query that re-authorizes using the refresh token
 * @see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
 */
import {
  fetchBaseQuery,
  retry,
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query'
import { logger } from '@tunasong/models'
import { Mutex } from 'async-mutex'
import invariant from 'tiny-invariant'
import { OAuth } from '../auth/index.js'
import { userSlice } from '../features/user/user-slice.js'

const MAX_RETRIES = 1

const mutex = new Mutex()

export interface Meta {
  eTag: string | null
  resumeKey: string | null
}
export interface ExtraOptions {
  skipAuthorization?: boolean
}

const createBaseQuery = (baseUrl: string, extraOptions: ExtraOptions) =>
  fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      /** Set authorization header if not set  */
      if (extraOptions?.skipAuthorization) {
        return headers
      }
      /** Set the authorization header */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const token = (getState() as any).user.authData?.idToken.jwtToken
      invariant(token, 'No token found')

      headers.set('Authorization', `Bearer ${token}`)

      return headers
    },
    baseUrl,
  })

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions,
  Meta & FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = api.getState() as any
  const baseUrl = state.config.apiBaseUrl
  /** @note retry is added here */
  const baseQuery: ReturnType<typeof createBaseQuery> = retry(createBaseQuery(baseUrl, extraOptions), {
    maxRetries: MAX_RETRIES,
  })
  let result = await baseQuery(args, api, extraOptions)

  if (result.error?.status === 401) {
    // checking whether the mutex is locked

    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        /**
         * We have two possible conditions here. We have a valid session, or we do not.
         * If we have a valid session, we should not retry as this is an item we don't have access to.
         * If we don't have a valid session, retry the request after refreshing the session.
         */

        /** We require valid Authdata in Redux  */
        const validSession = Boolean(state.user.authData) && !OAuth.isSessionExpired(state.user.authData)
        if (validSession) {
          /**
           * Throws the error - @see https://github.com/reduxjs/redux-toolkit/issues/3789
           * This is a bug in Redux Toolkit.
           * So for clients, they will receive the error, but as {error: {error}}
           */
          retry.fail(result.error)
        }
        /** We don't have a valid session, so get one */
        const sess = await OAuth.getSession()
        if (sess?.idToken.payload.sub) {
          api.dispatch(userSlice.actions.setAuth(sess))
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          OAuth.logout()
          api.dispatch(userSlice.actions.signout())
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  if (result.error) {
    // if (typeof result.error === ) {
    /** Aborted fetch calls */
    if (result.error.status === 'FETCH_ERROR' && result.error.error.startsWith('AbortError')) {
      return result as never
    }

    /** 404 are occuring during uploads, so we don't flag errors on those */
    if (result.error.status === 404) {
      return result as never
    }

    logger.error('Error loading data: ', result)
    // const alert: Alert = isOnline()
    //   ? { message: `Failed to load data: ${JSON.stringify(result.error)}`, severity: 'error' }
    //   : { message: 'Offline', severity: 'warning' }
    // api.dispatch(notificationsSlice.actions.setAlert(alert))
  }

  /** @note these must be declared in Access-Control-Expose-Headers, see tuna-api.ts */
  const eTag = result.meta?.response?.headers.get('etag') ?? null
  const resumeKey = result.meta?.response?.headers.get('x-resume-key') ?? null
  return {
    ...result,
    meta: result.meta && ({ ...result.meta, eTag, resumeKey } as never),
  }
}

export default baseQueryWithReauth
