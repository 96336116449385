import { ZodSchema } from 'zod'
import { audioClipSchema, isAudioClip, isEntityClip, type AudioClip, type Clip, type EntityClip } from './audio-clip.js'
import { audioCommentSchema, type AudioComment } from './audio-comment.js'
import { isAudioTrack, trackSchema, type Track } from './audio-track.js'
import { bandSpaceSchema, isBandSpace, type BandSpace } from './band-space.js'
import { isMedia, mediaSchema, type Media } from './media.js'
import { isSong, songSchema, type Song } from './song.js'
import {
  type Midi,
  type MidiProgramChange,
  type MidiCC,
  isMidi,
  isMidiCC,
  isMidiProgramChange,
  midiSchema,
} from './midi.js'
import {
  isCalendar,
  calendarSchema,
  type Calendar,
  isCalendarEvent,
  type CalendarEvent,
  calendarEventSchema,
  type Attendee,
} from './calendar.js'
import { musicXMLSchema, type MusicXML, isMusicXML } from './musicxml.js'
import { userSettingsSchema, type UserSettings, type UserSettingsData } from './user-settings.js'
import { effectSchema, type Effect, type EffectType, EffectTypeValues, isEffect } from './effect.js'
import { setlistSchema, type Setlist } from './setlist.js'

/** Declare the types here */
const extensionEntityNames = [
  'song',
  'audiotrack',
  'audioclip',
  'midi',
  'audiocomment',
  'bandspace',
  'calendar',
  'calendarevent',
  'musicxml',
  'usersettings',
  'effect',
  'setlist',
] as const
type ExtensionEntityName = (typeof extensionEntityNames)[number]

const extensionSchemas = {
  /** we use non-passthrough for page for now, gradually moving the others over  */
  audioclip: audioClipSchema.passthrough(),
  audiocomment: audioCommentSchema.passthrough(),
  audiotrack: trackSchema.passthrough(),
  song: songSchema.passthrough(),
  midi: midiSchema,
  bandspace: bandSpaceSchema.passthrough(),
  calendar: calendarSchema.passthrough(),
  calendarevent: calendarEventSchema.passthrough(),
  musicxml: musicXMLSchema.passthrough(),
  usersettings: userSettingsSchema.passthrough(),
  effect: effectSchema,
  setlist: setlistSchema,
} satisfies Record<ExtensionEntityName, ZodSchema<unknown>>

export {
  audioClipSchema,
  calendarEventSchema,
  audioCommentSchema,
  bandSpaceSchema,
  extensionEntityNames,
  userSettingsSchema,
  isAudioClip,
  isCalendarEvent,
  isAudioTrack,
  isBandSpace,
  isEntityClip,
  isMusicXML,
  isMedia,
  isMidi,
  isSong,
  isCalendar,
  isMidiCC,
  isMidiProgramChange,
  isEffect,
  mediaSchema,
  musicXMLSchema,
  calendarSchema,
  midiSchema,
  extensionSchemas,
  songSchema,
  trackSchema,
  setlistSchema,
  type Setlist,
  type Calendar,
  type Attendee,
  type CalendarEvent,
  type AudioClip,
  type AudioComment,
  type BandSpace,
  type Clip,
  type EntityClip,
  type Media,
  type Midi,
  type UserSettingsData,
  type UserSettings,
  type Song,
  type Track,
  type MusicXML,
  type MidiProgramChange,
  type MidiCC,
  type EffectType,
  type Effect,
  EffectTypeValues,
}
