/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from '../styles.js'

export const useEntityStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
      paddingTop: 0,
    },
  },
  content: {
    flex: 1,
  },

  link: {
    display: 'block',
  },
  title: {
    textAlign: 'center',
  },
  folder: {},

  roomChat: {
    padding: 0,
  },
  breadcrumbs: {
    flex: 1,
    // color: theme.palette.action.active,
  },
  fab: {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0) !important',
    bottom: theme.spacing(2),
    margin: '0 auto',
  },
  fabIcon: {
    height: '1.3em',
    width: '1.3em',
  },
  viewFab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(2),
    margin: '0 auto',
  },
  gridContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    height: '100%',
  },
  gridItem: {
    // flex: 3,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
  activities: {
    overflow: 'auto',
  },
  sidebar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
    margin: theme.spacing(4, 2, 0, 2),
  },
  sidebarIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: theme.palette.action.active,
  },
  sidebarButton: {
    // color: theme.palette.action.active,
    position: 'absolute',
    // top: '50%',
    // transform: 'translate(-50%, -50%)',
    top: theme.spacing(7),
    right: theme.spacing(2),
    zIndex: 1,
  },
  listMenu: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-between',
  },
  createNew: {
    minWidth: 300,
  },
  createMenu: {
    minWidth: 200,
  },
}))

export default useEntityStyles
