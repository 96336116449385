/** Get the hotkey for the command */

import { useUserConfig } from '@tunasong/redux'
import type { CommandHotkey } from '@tunasong/schemas'

/** Get the hotkey for the command.  */
export const useCmdHotkey = (cmd: string): CommandHotkey | null => {
  const [hotkeys] = useUserConfig('hotkeys')
  const hotkey = hotkeys.find(h => h.commandId === cmd)

  return hotkey ?? null
}
