import { Backdrop } from '@mui/material'
import { type FC } from 'react'
import Pulse from './pulse.js'

export interface FullPageProgressProps {
  show?: boolean
}

export const FullPageProgress: FC<FullPageProgressProps> = props => {
  const { show = true } = props

  return (
    <Backdrop
      open={show}
      sx={{
        display: show ? 'inherit' : 'none',
        backgroundColor: theme => theme.palette.divider,
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Pulse size="large" />
    </Backdrop>
  )
}

export default FullPageProgress
