import { useUserConfig } from '@tunasong/redux'
import { useContext, useEffect, useState } from 'react'
import { type TunaPlugin } from '../plugin-types.js'
import { maturityFilter } from '../plugin/maturity-filter.js'
import { type PluginConfigSet, PluginContext } from './plugin-context.js'

export const usePlugins = (type: PluginConfigSet | null = 'all') => {
  const repo = useContext(PluginContext)

  const [maturity] = useUserConfig('featureMaturity')

  const [pluginCollection, setCollection] = useState<TunaPlugin[]>()

  useEffect(() => {
    setCollection(undefined)
    if (!(repo && type)) {
      return
    }
    repo
      .loadCollection(type)
      ?.then(coll =>
        setCollection(coll.flatMap(plugin => [plugin, ...(plugin.plugins ?? [])]).filter(maturityFilter(maturity)))
      )
  }, [maturity, repo, type])

  return pluginCollection ?? null
}
