import { logger } from '@tunasong/models'
import { useCommandSpecRunner } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useEffect, useRef } from 'react'
import { useEventCommands } from '../../commands/event-commands.hook.js'
import { useUserConfig } from '@tunasong/redux'

/**
 * Run the command on mount, or when any of the parameters change.
 * This hook will run once.
 */
export const useRunEventCommands = ({ entity }: { entity?: Persisted<Entity> }) => {
  const [maturity] = useUserConfig('featureMaturity')

  const eventCommands = useEventCommands(entity)

  const runner = useCommandSpecRunner()
  const runCounter = useRef(0)

  useEffect(() => {
    if (!runner || !entity?.id || runCounter.current > 0) {
      // runner is not ready
      return
    }
    runCounter.current += 1
    logger.debug(`Running Enter event commands, count: ${runCounter.current}`)
    const onEnterCommandSpecs = eventCommands.onEnter ?? []
    const onLeaveCommandSpecs = eventCommands.onLeave ?? []

    for (const spec of onEnterCommandSpecs) {
      runner({ spec, targetEntityId: entity.id })
    }

    return () => {
      logger.debug(`Running Leave event commands, count: ${runCounter.current}`)
      for (const leaveCommand of onLeaveCommandSpecs) {
        runner({ spec: leaveCommand, targetEntityId: entity.id })
      }
    }
  }, [maturity, entity?.id, runner, eventCommands.onEnter, eventCommands.onLeave])
}
