import { Button, Menu, MenuItem, type ButtonProps } from '@mui/material'
import { Share } from '@tunasong/icons'
import { defaultSortOptions } from '@tunasong/models'
import { entitiesApi } from '@tunasong/redux'
import { useCallback, useState, type FC } from 'react'
import { useEntitySort } from '../hooks/sort.js'
import EntityLink from '../entity/link.js'

export interface BacklinksButtonProps extends ButtonProps {
  id?: string
}

export const BacklinksButton: FC<BacklinksButtonProps> = ({ id = '', ...restProps }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const { currentData: backlinks = [] } = entitiesApi.useLoadEntityBacklinksQuery({ id }, { skip: id === '' })

  const ids = backlinks
    .map(backlink => backlink.source)
    .filter(id => id !== 'ROOT')
    .filter(Boolean)
  const { currentData: linkedEntities = [] } = entitiesApi.useLoadEntitiesQuery(
    {
      ids,
    },
    { skip: ids.length === 0 }
  )
  const entities = useEntitySort(linkedEntities, defaultSortOptions)

  const numBacklinks = backlinks.length
  const label = numBacklinks > 0 ? `${numBacklinks} backlink${numBacklinks > 1 ? 's' : ''}` : ''

  return (
    <>
      <Button
        {...restProps}
        endIcon={<Share />}
        aria-controls={open ? 'backlink-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={backlinks.length === 0}
        onClick={handleClick}
      >
        {label}
      </Button>

      <Menu
        id="backlink-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'backlink-button',
        }}
      >
        {entities.map(entity =>
          entity ? (
            <MenuItem onClick={handleClose} key={entity.id}>
              <EntityLink entity={entity} />
            </MenuItem>
          ) : null
        )}
      </Menu>
    </>
  )
}

export default BacklinksButton
