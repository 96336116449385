/** Configuration state hook */

import { defaultUserConfig, type UserConfig } from '@tunasong/manifest'
import { useCallback, useEffect, useState } from 'react'
import { useAsyncUserConfigService } from './config-service.js'

// Convenience. Get the default configuration for a plugin, or an empty object if not found
export const getPluginDefaultConfig = (pluginName: string) => defaultUserConfig.plugins[pluginName as never] ?? {}

/** Get the user's configuration. If plugin is specified, return the plugin's specific configuration (under plugins/pluginName)  */
export const usePluginConfig = <T extends keyof UserConfig['plugins']>(pluginName: T) => {
  /** Currently we use the profile to store configuration */
  const { updatePluginConfig, getPluginConfig } = useAsyncUserConfigService()

  // getUserConfig is a promise, so we need to use a callback to set the value
  const [localConfig, setLocalConfig] = useState<UserConfig['plugins'][T] | undefined>(undefined)
  useEffect(() => {
    getPluginConfig(pluginName).then(config => {
      setLocalConfig(config)
    })
  }, [getPluginConfig, pluginName])

  const setPluginConfig = useCallback(
    <T extends keyof UserConfig['plugins']>(value: UserConfig['plugins'][T]) => updatePluginConfig(pluginName, value),
    [pluginName, updatePluginConfig]
  )

  return [localConfig, setPluginConfig] as const
}
