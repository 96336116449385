import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  type Breakpoint,
  type DialogProps,
  LinearProgress,
} from '@mui/material'
import classNames from 'classnames'
import React, { type FC } from 'react'
import { makeStyles } from '../styles.js'
import { DialogTitle } from './dialog-title.js'

export interface ConfirmDialogProps extends DialogProps {
  className?: string
  open: boolean
  title?: string
  /** Busy - disable all inputs / buttons and show a progress bar */
  busy?: boolean
  text: string
  maxWidth?: false | Breakpoint | undefined

  onCancel(ev: React.SyntheticEvent): void
  onConfirm(ev: React.SyntheticEvent): void
}

const useStyles = makeStyles()(() => ({
  root: {},
}))

export const ConfirmDialog: FC<ConfirmDialogProps> = props => {
  const { className, children, text, busy = false, open, onConfirm, onCancel, title = 'Confirm', ...restProps } = props
  const { classes } = useStyles()
  if (!open) {
    return null
  }
  return (
    <div className={classNames(className, classes.root)}>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...restProps}
      >
        <DialogTitle onClose={onCancel} id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
          {children}

          {busy ? <LinearProgress sx={{ mt: 2 }} /> : null}
        </DialogContent>
        <DialogActions>
          <Button disabled={busy} onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={busy} onClick={onConfirm} autoFocus={true}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog
