import { type CoreElement, isCoreElement } from '@tunasong/schemas'
import { type TunaElement } from '../element/editor.js'

export interface Link extends CoreElement {
  type: 'link'
  url: string
  icon?: string
  title: string
  description?: string
  pending?: boolean
}

export function isLink(node?: unknown): node is TunaElement<Link> {
  return Boolean(isCoreElement(node) && node.type === 'link')
}
