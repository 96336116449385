import { shortUuid } from '@tunasong/models'
import React, { useCallback, useEffect, useRef, type FC, type ReactNode } from 'react'
import {
  getPanelGroupElement,
  Panel,
  PanelGroup,
  PanelResizeHandle,
  type ImperativePanelGroupHandle,
  type PanelGroupOnLayout,
  type PanelGroupProps,
} from 'react-resizable-panels'

export interface PanelContainerProps extends PanelGroupProps {
  minSizes?: number[]
  maxSizes?: number[]
  defaultSizes?: number[]
  children: React.ReactNode
  direction: 'horizontal' | 'vertical'
  id?: string
  order?: number
  autoSaveId?: string
  linkId?: string
  elRef?: React.MutableRefObject<HTMLElement | null>
}

export const PanelContainer: FC<PanelContainerProps> = props => {
  const {
    elRef,
    minSizes,
    maxSizes,
    defaultSizes,
    id: providedId,
    direction = 'horizontal',
    autoSaveId,
    children,
    ...restProps
  } = props
  const ref = useRef<ImperativePanelGroupHandle>(null)

  const id = useRef(providedId ?? shortUuid()).current

  const childElements = (Array.isArray(children) ? (children as ReactNode[]) : [children]).filter(Boolean)

  const handleLayout: PanelGroupOnLayout = useCallback(layout => {
    console.log('layout', layout)
  }, [])
  // Handle the DOM element ref

  useEffect(() => {
    if (!elRef) {
      return
    }
    const dawPanelEl = getPanelGroupElement(id)
    elRef.current = dawPanelEl
  }, [elRef, id])

  return (
    <PanelGroup id={id} autoSaveId={autoSaveId} direction={direction} ref={ref} onLayout={handleLayout} {...restProps}>
      {childElements.map((child, idx) =>
        [
          <Panel
            key={`${id}-panel-${idx}`}
            id={`${id}-panel-${idx + 1}`}
            defaultSize={defaultSizes?.[idx]}
            minSize={minSizes?.[idx]}
            maxSize={maxSizes?.[idx]}
            order={idx + 1}
          >
            {child}
          </Panel>,
          idx < childElements.length - 1 ? <PanelResizeHandle key={`${idx}-handle`} /> : null,
        ].filter(Boolean)
      )}
    </PanelGroup>
  )
}
