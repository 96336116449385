import { entityTypeNames, isEntity, isPersisted, type Entity } from '@tunasong/schemas'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { mergeRefs } from 'react-merge-refs'
import invariant from 'tiny-invariant'
import { EntityListItem, type EntityListItemProps } from '../entity/entity-list-item.js'
import { makeStyles } from '../styles.js'
import type { DropTarget } from './constants.js'
import DndDivider from './dnd-divider.js'
import { useFavorites } from '../favorites/favorites.hook.js'

interface DraggableProps extends EntityListItemProps {
  element: Entity
  size?: 'small' | 'tiny' | 'large'

  /** -1 is top,  */
  onDropped?(source: Entity, target: DropTarget): void
  onHovering?(source: Entity, target: Entity): void
  isDropTarget(params: { source?: Entity; target: Entity }): boolean
}

const useStyles = makeStyles()(theme => ({
  dragging: {
    backgroundColor: theme.palette.primary.dark,
  },
  dropZone: {
    borderColor: theme.palette.success.light,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
  },
}))

export const DraggableListItem = ({ element, isDropTarget, onHovering, onDropped, ...restProps }: DraggableProps) => {
  const { classes } = useStyles()
  const { isFavorite } = useFavorites()

  const [{ isDragging, opacity }, dragRef, preview] = useDrag(
    () => ({
      type: element.type,
      item: element,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [element]
  )

  /** @todo this should probably be only in the DND version */
  const canDrop = useCallback((item: Entity) => Boolean(item?.id !== element?.id), [element])
  /** Send exactly one hover messsage per source, target pair */
  const lastHover = React.useRef<{ source: Entity; target: Entity }>()
  const [{ isHovering }, dropRef] = useDrop({
    accept: entityTypeNames as never,
    drop: item => {
      if (onDropped) {
        onDropped(item, element)
      }
    },
    hover: item => {
      if (item === lastHover.current?.source && element === lastHover.current?.target) {
        return
      }
      invariant(isEntity(item), 'Item must be an Entity')
      lastHover.current = { source: item, target: element }
      if (onHovering) {
        onHovering(item, element)
      }
    },
    canDrop,
    collect: monitor => ({
      isHovering: monitor.isOver({ shallow: true }), // canDrop(monitor.getItem()) &&,
    }),
  })

  const mergedRef = mergeRefs([dragRef, dropRef, preview].filter(Boolean))

  /**
   * @see https://react-dnd.github.io/react-dnd/examples/sortable/simple
   *
   * We have an issue where the drag preview is showing not just the item being dragged, but the entire list.
   * @see https://stackoverflow.com/questions/42234575/react-dnd-showing-entire-list-when-dragging
   *
   */

  return (
    <>
      {/* If we are hovering and we are not a drop target (e.g., folder), show a re-order divider */}
      {isHovering && lastHover.current && !isDropTarget(lastHover.current) ? <DndDivider /> : null}
      <EntityListItem
        {...restProps}
        sx={{ opacity }}
        className={classNames({
          [classes.dragging]: isDragging,
          [classes.dropZone]: isHovering && lastHover.current && isDropTarget(lastHover.current),
        })}
        favorite={isPersisted(element) && isFavorite(element)}
        element={element}
        ref={mergedRef}
      />
    </>
  )
}
