import { Box, type BoxProps, Button, type Theme } from '@mui/material'
import { type FilterFunc, isFolder, isPersistedEntity, matchAny, type NavigationEntity } from '@tunasong/models'
import { useEntities } from '@tunasong/redux'
import { type Entity, type Persisted, isAudio, isMedia } from '@tunasong/schemas'
import { isSong } from '@tunasong/schemas'
import { useCallback, useState } from 'react'
import { DndList } from '../list/dnd-list.js'
import { makeStyles } from '../styles.js'
import { EntityTree } from './entity-tree.js'
import type { NavFn } from '../navigation/navigate.js'
import invariant from 'tiny-invariant'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    maxHeight: '100%',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  selectedContainer: {
    minWidth: 200,
  },
}))

export interface TransferListProps extends Omit<BoxProps, 'onChange'> {
  parent?: Persisted<Entity>
  list: Persisted<Entity>[]
  filter?: FilterFunc
  onChange(list: Persisted<Entity>[]): void
}

const defaultFilter = matchAny(isSong, isAudio, isFolder)

export function TransferList(props: TransferListProps) {
  const { list = [], filter: filterFunc = defaultFilter, onChange, ...restProps } = props
  const { classes } = useStyles()
  const { entities: rootEntities } = useEntities({ root: true, filter: filterFunc })
  const [selectedList, setCheckedList] = useState<Persisted<Entity>[]>([])
  const [selected, setSelected] = useState<Persisted<Entity>>()

  const handleAdd = useCallback(() => {
    if (!(selected && list)) {
      return
    }
    onChange([...list, selected])
    setSelected(undefined)
  }, [list, onChange, selected])

  const handleRemove = useCallback(() => {
    const c = list.filter(s => !selectedList.includes(s))
    setCheckedList([])
    onChange(c)
  }, [list, onChange, selectedList])

  const handleSelectTree = useCallback((value: Persisted<Entity>) => {
    const val = isMedia(value) ? value : undefined
    setSelected(val)
  }, [])

  const handleSelectList: NavFn = useCallback((value?: NavigationEntity) => {
    invariant(isPersistedEntity(value))
    setCheckedList([value])
  }, [])

  const notDropTarget = useCallback(() => false, [])

  return (
    <Box className={classes.root} {...restProps}>
      <EntityTree rootEntities={rootEntities} selected={selected} filter={filterFunc} onSelect={handleSelectTree} />
      <Box display="flex" flexDirection="column">
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAdd}
          disabled={!selected}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleRemove}
          disabled={selectedList.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
      </Box>

      {/* Use DnDList for DnD sorting */}
      <DndList
        className={classes.selectedContainer}
        entities={list}
        isDropTarget={notDropTarget}
        header={false}
        actions={false}
        onChange={onChange}
        selected={selectedList}
        onOpen={handleSelectList}
        navigate={false}
      />
    </Box>
  )
}
